/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoleKey } from '@upper/graphql/auth'
import { Spinner } from '@upper/ui'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Api } from './api'
import { AuthContext, AuthContextInterface } from './context'
import type { User } from './types'

export function AuthProvider({
  appName,
  children,
  omit = false,
}: {
  appName: string
  children: React.ReactNode
  omit?: boolean
}) {
  const [user, setUser] = React.useState<User | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState<any | null>(null)
  const router = useRouter()

  const api = new Api(appName)

  React.useEffect(() => {
    async function fetchUser() {
      try {
        const response = await api.me()
        window.dispatchEvent(
          new CustomEvent('upper-u-me', {
            detail: {
              id: response.data.id,
              email: response.data.email,
              name: `${response.data.firstName} ${response.data.lastName}`,
            },
          } as any)
        )
        setUser(response.data)
        setIsLoading(false)
      } catch (e: any) {
        if (e?.status !== 401) {
          console.log(e)
          setError(e?.data)
        }

        setIsLoading(false)
      }
    }
    if (!omit) fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [omit])

  const userHasRole = React.useCallback(
    (role: RoleKey | RoleKey[]) => {
      if (!user) return false

      const roles = Array.isArray(role) ? role : [role]

      return user.userRoles.some((r) => roles.includes(r.role.key))
    },
    [user]
  )

  const login = React.useCallback(
    (data: any) =>
      api.login(data).then(() => {
        window.dispatchEvent(
          new CustomEvent('upper-u-in', {
            detail: { email: data.email },
          } as any)
        )
        if (router.query.returnTo) {
          window.location.href = router.query.returnTo as string
        } else {
          window.location.href = '/'
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.query]
  )

  const logout = React.useCallback(() => {
    api.logout().then(() => {
      window.dispatchEvent(
        new CustomEvent('upper-u-out', { detail: undefined } as any)
      )
      window.location.href = '/login'
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = React.useMemo(
    () => ({ user, userHasRole, login, logout }),
    [user, userHasRole, login, logout]
  )

  if (isLoading && !omit) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner />
      </div>
    )
  }
  if (error) return <p>Error {error?.message}</p>

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

function useAuth(): AuthContextInterface {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export { useAuth }
