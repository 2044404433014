import { cacheExchange } from '@urql/exchange-graphcache'
import {
  CombinedError,
  createClient,
  errorExchange,
  Exchange,
  // cacheExchange,
  fetchExchange,
} from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import { Api } from './api'

export async function onUrqlError(error: CombinedError) {
  const isAuthError = error.graphQLErrors.some(
    (e) => e.extensions?.code === 'UNAUTHENTICATED'
  )

  const api = new Api('')

  if (isAuthError) {
    await api.logout()
    const url = new URL('/login', window.location.href)
    url.searchParams.append('returnTo', window.location.pathname)
    window.location.replace(url.toString())
    return null
  } else {
    error.message = error.message?.replace('[GraphQL] ', '')
    return error
  }
}

export function createUrqlClient(appName: string, cache: Exchange | null) {
  const exchanges = [
    devtoolsExchange,
    errorExchange({
      onError: onUrqlError,
    }),
    fetchExchange,
  ]
  if (cache) {
    exchanges.splice(2, 0, cache)
  }
  return createClient({
    url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    exchanges,
    fetchOptions: {
      credentials: 'include',
      headers: {
        'x-app': appName,
      },
    },
  })
}

export const urqlClient = createClient({
  url: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
  exchanges: [
    cacheExchange({}),
    errorExchange({
      onError: onUrqlError,
    }),
    fetchExchange,
  ],
  fetchOptions: {
    credentials: 'include',
  },
})
