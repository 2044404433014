import { HTMLAttributes, PropsWithChildren } from 'react'
import { classNames } from '../classnames'
import { Section } from './components'

const borderRadiusClasses = {
  light: 'rounded',
  normal: 'rounded-md',
  heavy: 'rounded-xl',
}

const paddingClasses = {
  light: 'children:px-4 children:py-4',
  normal: 'children:py-9 children:px-7',
  heavy: 'children:py-9 children:px-7',
  none: '',
}

type CardProps = PropsWithChildren<{
  className?: string
  style?: Partial<HTMLAttributes<HTMLDivElement>['style']>
  borderRadius?: 'light' | 'normal' | 'heavy'
  padding?: 'light' | 'normal' | 'heavy' | 'none'
}>

export function Card({
  children,
  className,
  style,
  borderRadius = 'normal',
  padding = 'normal',
}: CardProps): JSX.Element {
  const composedClassName = classNames(
    className,
    'bg-white shadow-xl',
    borderRadius && borderRadiusClasses[borderRadius],
    padding && paddingClasses[padding]
  )

  return (
    <div className={composedClassName} style={style}>
      {children}
    </div>
  )
}

Card.Section = Section
