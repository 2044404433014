import { onKeyDown, withListsReact } from '@prezly/slate-lists'
import {
  BoldIcon,
  BulletedListIcon,
  ItalicIcon,
  NumberedListIcon,
} from '@upper/icons'
import { cn } from '@upper/sapphire/ui'
import escapeHtml from 'escape-html'
import isHotkey from 'is-hotkey'
import React, { ReactNode, createElement, useEffect, useState } from 'react'
import {
  BaseEditor,
  BaseElement,
  Descendant,
  Editor,
  Text,
  Transforms,
  createEditor,
} from 'slate'
import { HistoryEditor, withHistory } from 'slate-history'
import { jsx } from 'slate-hyperscript'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlate,
  withReact,
} from 'slate-react'
import { WithListType, withListsPlugin } from './editor/with-lists'
import { FormLabel } from './form-label'

export type HeadingElement = {
  type: 'heading'
  level: number
  children: CustomText[]
}

type BulletedListElement = {
  type: 'bulleted-list'
  children: Descendant[]
}

type NumberedListElement = {
  type: 'numbered-list'
  children: Descendant[]
}

type ListItemElement = {
  type: 'list-item'
  children: Descendant[]
}

type ParagraphElement = {
  type: 'paragraph'
  children: Descendant[]
}

type CustomElement =
  | ({ type: WithListType } & BaseElement)
  | BulletedListElement
  | NumberedListElement
  | ListItemElement
  | ParagraphElement
  | HeadingElement

type CustomText = {
  text: string
  bold?: boolean
  italic?: boolean
  indentLevel?: number
}

type CustomEditor = BaseEditor &
  ReactEditor &
  HistoryEditor & {
    indentLevel?: number
  }

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor
    Element: CustomElement
    Text: CustomText
    onKeyDown?: (event: React.KeyboardEvent) => void
  }
}

const ELEMENT_TAGS: any = {
  A: (el?: any) => ({ type: 'link', url: el?.getAttribute('href') }),
  BLOCKQUOTE: () => ({ type: 'quote' }),
  IMG: (el?: any) => ({ type: 'image', url: el?.getAttribute('src') }),
  LI: () => ({ type: 'list-item' }),
  OL: () => ({ type: 'numbered-list' }),
  P: () => ({ type: 'paragraph' }),
  PRE: () => ({ type: 'code' }),
  UL: () => ({ type: 'bulleted-list' }),
  TABLE: () => ({ type: 'table' }),
  TBODY: () => ({ type: 'tbody' }),
  THEAD: () => ({ type: 'thead' }),
  TR: () => ({ type: 'table-row' }),
  TD: () => ({ type: 'table-cell' }),
  TH: () => ({ type: 'table-cell-header' }),
  HEADER: () => ({ type: 'header' }),
  SECTION: () => ({ type: 'section' }),
  H1: () => ({ type: 'heading', level: 1 }),
  H2: () => ({ type: 'heading', level: 2 }),
  H3: () => ({ type: 'heading', level: 3 }),
  H4: () => ({ type: 'heading', level: 4 }),
}
const TEXT_TAGS: any = {
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
}

export const serialize = (node: any) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)
    if (node.bold) {
      string = `<strong>${string}</strong>`
    }
    if (node.italic) {
      string = `<em>${string}</em>`
    }
    return string
  }

  const children = node.children.map(serialize).join('')
  if (Array.isArray(node.children)) {
    switch (node.type) {
      case 'bulleted-list':
        return `<ul>${children}</ul>`
      case 'numbered-list':
        return `<ol>${children}</ol>`
      case 'list-item':
        return `<li>${children}</li>`
      case 'list-item-text':
        return `<div>${children}</div>`
      case 'paragraph':
        return `<p>${children}</p>`
      case 'heading':
        return `<h${node.level}>${children}</h${node.level}>`
      case 'link':
        return `<a href="${escapeHtml(node.url)}">${children}</a>`
      default:
        return children
    }
  }
  return node.text ?? ''
}

export const deserialize = (
  el: any,
  markAttributes = {},
  isRoot = true
): any => {
  const nodeAttributes: any = { ...markAttributes }

  if (el.nodeType === 3) {
    return el.textContent
      ? isRoot
        ? { type: 'paragraph', children: [{ text: el.textContent }] }
        : { text: el.textContent }
      : null // Make sure text nodes are returned as { text: '...' } objects
  } else if (el.nodeType !== 1) {
    return null
  }

  const { nodeName } = el

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes, isRoot))
    .flat()
    .filter(Boolean) // Filter out null or undefined values

  if (children.length === 0) {
    return null
  }

  switch (el.nodeName) {
    case 'PRE':
      nodeAttributes.code = true
      break
    case 'STRONG':
      nodeAttributes.bold = true
      break
  }

  if (TEXT_TAGS[nodeName]) {
    return children.map((child) => {
      if (typeof child === 'string') {
        return jsx('text', TEXT_TAGS[nodeName](child), { text: child }) // Ensure string children are wrapped as text nodes
      }
      return isRoot
        ? jsx('element', TEXT_TAGS[nodeName](child), child)
        : jsx('text', TEXT_TAGS[nodeName](child), child)
    })
  }

  if (ELEMENT_TAGS[nodeName]) {
    return jsx(
      'element',
      ELEMENT_TAGS[nodeName](el),
      Array.from(el.childNodes).map((e) => deserialize(e, {}, false))
    )
  }

  switch (nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BR':
      return { text: '\n' } // Wrap BR as a text node
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'quote' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    default:
      return children
  }
}

const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export type RichTextEditorProps = {
  label?: string
  initialValue?: string
  className?: string
  required?: boolean
  showCount?: boolean
  minChars?: number
  isInvalid?: ReactNode
  isReadonly?: boolean
  placeholder?: string
  onChange: (value: string) => void
  onBlur?: () => void
}

export function RichTextEditor({
  label,
  initialValue,
  className,
  required,
  showCount,
  minChars,
  isInvalid,
  isReadonly,
  placeholder,
  onChange,
  onBlur,
}: RichTextEditorProps) {
  const [value, setValue] = React.useState<Descendant[]>(() => {
    if (!initialValue) {
      return [{ type: 'paragraph', children: [{ text: '' }] }]
    }
    const parser = new DOMParser()
    const doc = parser.parseFromString(
      initialValue.replace(/>\s+</g, '><'),
      'text/html'
    )

    const slateNodes = deserialize(doc.body)

    if (!slateNodes?.[0]?.children) {
      return [{ type: 'paragraph', children: [{ text: '' }] }]
    }
    return slateNodes
  })

  const editor = React.useMemo(
    () =>
      withListsReact(
        withListsPlugin(withHtml(withHistory(withReact(createEditor()))))
      ),
    []
  )

  const renderElement = React.useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  )

  const renderLeaf = React.useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  )

  return (
    <div>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <div
        className={cn(
          'rounded-md border border-slate-300 bg-white text-slate-600 outline-none transition-all focus-within:!border-blue-500 focus-within:ring-1 focus-within:ring-blue-500',
          isInvalid ? '!border-red-500 ring-1 ring-red-500' : '',
          className
        )}
      >
        <Slate
          editor={editor}
          initialValue={value ? value : serialize(placeholder)}
          onChange={(newValue) => {
            setValue(newValue)

            const isAstChange = editor.operations.some(
              (op: any) => 'set_selection' !== op.type
            )
            if (isAstChange) {
              onChange(serialize(editor))
            }
          }}
        >
          <div
            className={cn(
              'flex flex-wrap items-center gap-1 rounded-t-md border-b border-slate-200 bg-slate-100 px-1 py-1',
              isReadonly && 'pointer-events-none hidden'
            )}
          >
            <MarkButton format="bold">
              <BoldIcon className="h-4 w-4" />
            </MarkButton>
            <MarkButton format="italic">
              <ItalicIcon className="h-4 w-4" />
            </MarkButton>
            <div className="mx-1 h-6 w-[1px] rounded bg-slate-300"></div>
            <BlockButton format="bulleted-list">
              <BulletedListIcon className="h-4 w-4" />
            </BlockButton>
            <BlockButton format="numbered-list">
              <NumberedListIcon className="h-4 w-4" />
            </BlockButton>
            <div className="mx-1 h-6 w-[1px] rounded bg-slate-300"></div>
            <BlockButton format="heading" level={1}>
              <span className="text-xs font-semibold">H1</span>
            </BlockButton>
            <BlockButton format="heading" level={2}>
              <span className="text-xs font-semibold">H2</span>
            </BlockButton>
            <BlockButton format="heading" level={3}>
              <span className="text-xs font-semibold">H3</span>
            </BlockButton>
            <BlockButton format="heading" level={4}>
              <span className="text-xs font-semibold">H4</span>
            </BlockButton>
          </div>
          <Editable
            className="prose text-foreground min-h-fit max-w-none p-3 outline-none"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly={isReadonly}
            onKeyDown={(event) => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
              return onKeyDown(editor, event)
            }}
            onBlur={onBlur}
          />
          {placeholder && <SlatePlaceholder placeholder={placeholder} />}
          {showCount && (
            <CharsCount
              showCount={showCount}
              minChars={minChars}
              value={value}
            />
          )}
        </Slate>
      </div>
    </div>
  )
}

export const SlatePlaceholder = ({ placeholder }: { placeholder?: string }) => {
  const { children } = useSlate()
  const isEmpty = children.every((d: any) => getIsEmpty(d))

  function getIsEmpty(node: Element | Text) {
    if (
      ('children' in node && !node.children.length) ||
      ('text' in node && node.text === '')
    )
      return true
    if ('children' in node)
      return (node.children as any)?.every?.((d: any) => getIsEmpty(d))
    return false
  }

  return isEmpty ? (
    <p
      className="prose pointer-events-none p-3 opacity-40"
      style={{ marginTop: '-52px' }}
      dangerouslySetInnerHTML={{ __html: placeholder ?? '' }}
    ></p>
  ) : null
}

const countChars = (content: any) => {
  if (!content) return 0
  let count = content.length > 1 ? content.length - 1 : 0
  content.forEach((value: any) => {
    count += value?.['children']?.[0]?.['text']?.length ?? 0
  })
  return count
}

const CharsCount = ({
  showCount,
  minChars,
  value,
}: {
  showCount?: boolean
  minChars?: number
  value: any
}) => {
  const [charCount, setCharCount] = useState(0)

  useEffect(() => {
    setCharCount(countChars(value))
  }, [value])

  return (
    <>
      {showCount && minChars && (
        <span className="text-muted pointer-events-none ml-auto block w-24 px-1 text-right text-xs">
          {charCount} / {minChars}
        </span>
      )}
    </>
  )
}

const isTypeActive = (editor: Editor, format: string, level?: number) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) =>
      level ? n.type === format && n.level === level : n.type === format,
  })
  return !!match
}

const toggleBlock = (editor: any, format: any, level = 0) => {
  const isActive = isTypeActive(editor, format, level)
  const isList = isTypeActive(editor, 'list-item')

  if (!isActive) {
    switch (format) {
      case 'heading':
        Transforms.wrapNodes(editor, { type: 'heading', level, children: [] })
        break
      case 'list-item':
        Transforms.wrapNodes(editor, { type: 'list-item', children: [] })
        break
      case 'bulleted-list':
        Transforms.wrapNodes(editor, { type: 'bulleted-list', children: [] })
        break
      case 'numbered-list':
        Transforms.wrapNodes(editor, { type: 'numbered-list', children: [] })
        break
      default:
        Transforms.setNodes(editor, { type: 'paragraph', children: [] })
        break
    }
  } else {
    switch (format) {
      case 'heading':
        Transforms.unwrapNodes(editor, {
          match: (n: any) => n.type === 'heading',
          split: true,
        })
        break
      case 'list-item':
        Transforms.unwrapNodes(editor, {
          match: (n: any) => n.type === 'list-item',
          split: true,
        })
        break
      case 'bulleted-list':
      case 'numbered-list':
        Transforms.unwrapNodes(editor, {
          match: (n: any) =>
            ['bulleted-list', 'numbered-list'].includes(n.type as string),
          split: true,
        })
        break
      default:
        Transforms.unwrapNodes(editor, {
          match: (n: any) => n.type === 'paragraph',
          split: true,
        })
        break
    }
  }

  console.log(isActive, format, level)
  return

  Transforms.unwrapNodes(editor, {
    match: (n: any) => n.type === 'list-item',
    split: true,
  })

  Transforms.unwrapNodes(editor, {
    match: (n: any) =>
      ['bulleted-list', 'numbered-list'].includes(n.type as string),
    split: true,
  })

  if (!isActive || !isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
    Transforms.wrapNodes(editor, { type: 'list-item', children: [] })
  }
}

const toggleMark = (editor: any, format: any, level?: number) => {
  const isActive = isMarkActive(editor, format, level)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}
const isBlockActive = (editor: any, format: any, level?: number) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) =>
      n.type === 'heading' ? n.level === level : n.type === format,
  })
  return !!match
}

const isMarkActive = (editor: any, format: any, level?: number) => {
  const marks = Editor.marks(editor) as any
  return marks ? marks[format] === true : false
}

const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: any
  children: any
  element: any
}) => {
  switch (element.type) {
    case WithListType.PARAGRAPH:
      return <p {...attributes}>{children}</p>
    case WithListType.ORDERED_LIST:
      return <ol {...attributes}>{children}</ol>
    case WithListType.UNORDERED_LIST:
      return <ul {...attributes}>{children}</ul>
    case WithListType.LIST_ITEM:
      return <li {...attributes}>{children}</li>
    case WithListType.LIST_ITEM_TEXT:
      return <div {...attributes}>{children}</div>
    case 'heading':
      return createElement(`h${element.level}`, attributes, children)
    case 'link':
      return (
        <a
          {...attributes}
          href={element.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({
  attributes,
  children,
  leaf,
}: {
  attributes: any
  children: any
  leaf: any
}) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const ToolbarButton = ({
  isActive,
  children,
  onMouseDown,
}: {
  isActive?: boolean
  children: React.ReactNode
  onMouseDown: (event: any) => void
}) => {
  return (
    <button
      type="button"
      className={cn(
        'inline-flex h-8 w-8 items-center justify-center rounded-md',
        isActive ? 'text-primary bg-blue-300/20' : 'text-slate-600'
      )}
      onMouseDown={onMouseDown}
    >
      {children}
    </button>
  )
}

const BlockButton = ({
  format,
  level,
  children,
}: {
  format: string
  level?: number
  children: React.ReactNode
}) => {
  const editor = useSlate()

  return (
    <ToolbarButton
      isActive={isBlockActive(editor, format, level)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format, level)
      }}
    >
      {children}
    </ToolbarButton>
  )
}

const MarkButton = ({
  format,
  children,
  level,
}: {
  format: string
  children: React.ReactNode
  level?: number
}) => {
  const editor = useSlate()
  return (
    <ToolbarButton
      isActive={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {children}
    </ToolbarButton>
  )
}

const withHtml = (editor: any) => {
  const { insertData, isInline, isVoid } = editor

  editor.isInline = (element: any) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertData = (data: any) => {
    const html = data.getData('text/html')

    if (html) {
      const parsed = new DOMParser().parseFromString(html, 'text/html')
      const fragment = deserialize(parsed.body)
      Transforms.insertFragment(editor, fragment)
      return
    }

    insertData(data)
  }

  return editor
}
