import {
  FormErrorMessage,
  RichTextEditor,
  RichTextEditorProps,
} from '@upper/ui'
import { useField } from 'formik'
import { FormikFieldProps } from './types'

type FormikRichTextEditorProps = FormikFieldProps &
  Omit<RichTextEditorProps, 'initialValue' | 'onChange'>

export function FormikRichTextEditor({
  name,
  ...restProps
}: FormikRichTextEditorProps) {
  const [, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  return (
    <div>
      <RichTextEditor
        initialValue={value}
        onChange={(value) => {
          setValue(value)
        }}
        onBlur={() => {
          helpers.setTouched(true)
        }}
        {...restProps}
        isInvalid={meta.error && meta.touched}
      />
      {meta.error && meta.touched && (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      )}
    </div>
  )
}
