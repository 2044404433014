import { CrossIcon } from '@upper/icons'
import {
  cloneElement,
  FocusEventHandler,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
} from 'react'
import { classNames } from './classnames'

type Props = {
  isFocused?: boolean
  isInvalid?: React.ReactNode
  isHighlighted?: boolean
  startAddon?: React.ReactNode
  endAddon?: React.ReactNode
  suffix?: string
  prefix?: string
  clearable?: boolean
  showClear?: boolean
  onFocus?: FocusEventHandler<HTMLElement>
  onBlur?: FocusEventHandler<HTMLElement>
  onClear?: () => void
  extra?: ReactNode
  className?: string
}
export const FieldBase = forwardRef<HTMLElement, PropsWithChildren<Props>>(
  (
    {
      children,
      isFocused,
      isInvalid,
      startAddon,
      endAddon,
      prefix,
      suffix,
      clearable,
      showClear,
      onClear,
      extra,
      className,
      isHighlighted,
      ...props
    },
    externalRef
  ) => {
    const childRef = useRef<HTMLElement | undefined>()

    const handleRef = useCallback(
      (node: HTMLElement) => {
        childRef.current = node
        if (externalRef != null) {
          if (typeof externalRef === 'object') {
            ;(externalRef as React.MutableRefObject<HTMLElement>).current = node
          } else if (typeof externalRef === 'function') {
            ;(externalRef as (instance: HTMLElement) => void)(node)
          }
        }
      },
      [externalRef]
    )
    const leftPadding = startAddon || prefix ? 'pl-0' : 'pl-3'
    const rightPadding =
      endAddon || suffix || clearable || extra ? 'pr-0' : 'pr-3'
    const wrapperLeftPadding = prefix ? 'pl-3' : 'pl-0'
    const wrapperRightPadding = suffix || clearable || extra ? 'pr-3' : 'pr-0'

    const element: JSX.Element = cloneElement(children as JSX.Element, {
      className: classNames(
        leftPadding,
        rightPadding,
        'py-3 px-0 h-full',
        'border-none outline-none shadow-none text-left placeholder-slate-300 font-medium bg-transparent',
        'focus:shadow-none focus:border-none focus:ring-0',
        'whitespace-nowrap overflow-hidden overflow-ellipsis w-full flex-shrink min-w-0 w-full',
        'disable:pointer-events-none disabled:text-slate-400 disabled:border-white disabled:placeholder-slate-300',
        isHighlighted ? 'text-blue' : 'text-slate-600'
      ),
      ref: handleRef,
      ...props,
    })

    const disabled = element.props.disabled

    const baseClassName: HTMLDivElement['className'] = classNames(
      wrapperLeftPadding,
      wrapperRightPadding,
      'flex gap-3 items-center w-full max-w-full font-medium rounded-md border border-slate-200 transition-all overflow-hidden h-[50px]',
      'focus-within:!ring-blue-500 focus-within:!border-blue-500 focus focus-within:!ring-1',
      isHighlighted ? 'border-blue-500 bg-[#E3E9F8]' : 'bg-white',
      isFocused && '!border-blue-500',
      Boolean(isInvalid) && '!border-red-500',
      // ((clearable && showClear && onClear) || suffix) &&
      //   !endAddon &&
      //   rightPadding
      className
    )

    return (
      <div
        className={classNames(
          baseClassName,
          isFocused
            ? 'border-blue-500 ring-blue-500'
            : isInvalid
            ? 'border-red-500 ring-red-500'
            : 'border-slate-200',
          Boolean(isInvalid) && 'ring-1',
          isFocused && 'ring-1',
          disabled && 'text-slate-400'
        )}
        onClick={() => childRef.current?.focus()}
      >
        {/* addon start */}
        {startAddon && (
          <TextFieldAddon
            position="left"
            className="rounded-l-md border-r"
            isHighlighted={isHighlighted}
          >
            {startAddon}
          </TextFieldAddon>
        )}
        {/* prefix */}
        {prefix && <TextFieldFix>{prefix}</TextFieldFix>}
        {element}
        {/* clearable */}
        {clearable && showClear && onClear && (
          <button
            className={classNames('h-full text-gray-600')}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onClear()
            }}
            title="Clear"
          >
            <CrossIcon />
          </button>
        )}
        {/* extra */}
        {extra && <span className="relative block">{extra}</span>}
        {/* suffix */}
        {suffix && <TextFieldFix>{suffix}</TextFieldFix>}
        {/* addon end */}
        {endAddon && (
          <TextFieldAddon
            position="right"
            className="rounded-r-md border-l"
            isHighlighted={isHighlighted}
          >
            {endAddon}
          </TextFieldAddon>
        )}
      </div>
    )
  }
)

type DecorationsProps = {
  children: React.ReactNode
  position: 'left' | 'right'
  className?: string
  isHighlighted?: boolean
}

function TextFieldAddon({
  children,
  position,
  className,
  isHighlighted,
}: DecorationsProps) {
  const style: React.CSSProperties = { top: 0, bottom: 0 }
  if (position === 'left') {
    style.left = 0
  } else {
    style.right = 0
  }

  return (
    <span
      className={classNames(
        'pointer-events-none flex items-center justify-center border-slate-200 bg-slate-50 px-4 py-4 text-slate-600',
        'h-full',
        isHighlighted && 'border-blue-500',
        className
      )}
      style={style}
    >
      {children}
    </span>
  )
}
function TextFieldFix({
  children,
  className,
}: Omit<DecorationsProps, 'position'>) {
  return (
    <span
      className={classNames(
        'h-full',
        'text-muted pointer-events-none flex items-center justify-center',
        className
      )}
    >
      {children}
    </span>
  )
}

export type { Props as FieldBaseProps }
