import { SelectField, SelectFieldAsyncProps, SelectFieldProps } from '@upper/ui';
import { useField } from 'formik';
import { useCallback } from 'react';

type FormikSelectFieldProps = { name: string; asArrayOfValues?: boolean } & (
  | SelectFieldProps
  | SelectFieldAsyncProps
)

export function FormikSelectField({
  asArrayOfValues,
  ...props
}: FormikSelectFieldProps) {
  const { name, isMulti, options, defaultValue } = props

  const getValue = useCallback(
    (option:any) => {
      if (props.getOptionValue) return props.getOptionValue(option)
      else return option.value
    },
    [props]
  )

  const [field, meta, helpers] = useField({
    name: props.name,
    defaultValue:
      !asArrayOfValues &&
      (isMulti
        ? (defaultValue as any)?.map((o: any) => getValue(o))
        : defaultValue),
    multiple: props.isMulti,
    required: props.required,
  })

  const handleChange = (options: any) => {
    if (!asArrayOfValues) {
      helpers.setValue(options)
      return
    }
    if (!options && isMulti) {
      helpers.setValue([])
    } else if (Array.isArray(options)) {
      helpers.setValue(options.map((so:any) => getValue(so)))
    } else {
      helpers.setValue(getValue(options))
    }
  }

  const value = [...(options || [])].filter((o) =>
    isMulti
      ? field.value && field.value.includes(getValue(o))
      : field.value === getValue(o)
  )

  return (
    <SelectField
      instanceId={name}
      isInvalid={meta.error && meta.touched}
      invalidMessage={meta.error}
      {...props}
      value={asArrayOfValues ? value : field.value}
      onChange={handleChange}
      onMenuClose={() => helpers.setTouched(true, true)}
    />
  )
}
